<template>
  <Large v-if="breakpoint.smOnly || breakpoint.mdOnly || breakpoint.lgOnly || breakpoint.xlOnly" :items="items" />
  <Small v-else :items="items" />
</template>

<script>
import Large from './large.vue';
import Small from './small.vue';

export default {
  name: 'PageSteps',
  components: { Large, Small },
  data() {
    return {
      items: [
        {
          numb: '01',
          name: 'Recognizing the partner',
          img: '01-carusel.png',
          text: 'We understand the tasks and the business of the partner.',
        },
        {
          numb: '02',
          name: 'Analyzing the markets and choosing a strategy',
          pr: '0',
          img: '02-carusel.jpg',
          text: 'The choice of markets based on the partners’ capabilities and the choice of a strategy for working on them.',
        },
        {
          numb: '03',
          name: 'Preparing a partner',
          img: '03-carusel.jpg',
          imgMobile: '03-carusel-mobile',
          text: 'Choosing an assortment. Determining the logistics. We help with the adaptation of processes.',
        },
        {
          numb: '04',
          name: 'Preparing the marketplaces',
          img: '04-carusel.jpg',
          text: 'Creating listings. Adapting or creating the content. Setting up the technical parameters.',
        },
        {
          numb: '05',
          name: 'Sales management',
          img: '05-carusel.jpg',
          text: 'Launching sales. Analyzing the data. Making adjustments.',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>
