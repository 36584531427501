<template>
  <v-form ref="form" v-model="valid" class="form">
    <v-text-field
      v-model="name"
      class="form__input rules pb-4"
      color="white"
      dense
      label="Name"
      :rules="[rules.required('Enter name')]"
    />
    <v-text-field
      v-model="phone"
      class="form__input rules pb-4"
      color="white"
      dense
      label="Phone"
      :rules="[rules.required('Enter phone')]"
    />
    <v-text-field
      v-model="email"
      class="form__input"
      color="white"
      dense
      label="Email"
      :rules="[rules.required('Enter E-mail'), rules.email]"
    />
    <v-text-field
      v-model="message"
      class="rules form__input form__input--last"
      color="white"
      dense
      :rules="[rules.required('Enter message')]"
      label="Your message"
    />
    <div class="d-flex justify-end align-center form__div">
      <p class="mr-3 ma-0 form__btn white--text">Send</p>
      <v-btn tile color="white" :disabled="!valid" @click="sendForm">
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="dialog" width="250">
      <v-card>
        <v-card-text class="pt-3 pb-0 form__dialog"> Your form has been submitted successfully! </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { patternEmail } from '@/utils/helpers';
import axios from 'axios';

export default {
  name: 'Large',

  data() {
    return {
      name: null,
      phone: null,
      email: null,
      message: null,
      dialog: false,
      valid: false,
      rules: {
        required: (msg) => (v) => !!v || msg,
        email: (v) => patternEmail.test(v) || 'Invalid e-mail',
      },
    };
  },
  computed: {},
  methods: {
    clearForm() {
      this.name = null;
      this.phone = null;
      this.email = null;
      this.message = null;
    },
    async sendForm() {
      const config = {
        url: 'https://formspree.io/f/moqygzzq',
        method: 'post',
        data: {
          name_input: this.name,
          email_input: this.email,
          phone_input: this.phone,
          message_input: this.message,
        },
      };
      axios.defaults.withCredentials = false;

      const res = await axios(config);

      if (res) {
        this.clearForm();
        this.$refs.form.resetValidation();
        this.dialog = true;

        setTimeout(() => {
          this.dialog = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss">
.rules .v-text-field__details {
  display: none !important;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: white !important;
}

.form {
  padding-top: 0px;

  .v-icon {
    font-size: 26px;
  }

  &__dialog,
  &__btn {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
  }

  &__dialog {
    font-weight: 600;
    font-size: 17px;
    line-height: 29px;
  }

  &__btn {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  .theme--light.v-btn {
    max-width: 60px;
    height: 60px;
    min-width: 60px;
    padding: 0;
  }
  @media (max-width: 1264px) {
    .theme--light.v-btn {
      max-width: 30px;
      min-width: 30px;
      height: 30px;
      padding: 0;
    }
    .v-icon {
      font-size: 15px;
    }
    &__btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 600px) {
    padding-bottom: 80px;
    &__div {
      padding-right: 14px;
    }
    &__input {
      padding: 14px 14px 0 14px;
    }
    &__btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
    .theme--light.v-btn {
      max-width: 60px;
      min-width: 60px;
    }
  }
  @media (max-width: 640px) {
  }
  &__input--last.v-text-field .v-text-field__details .v-messages {
    min-height: 0 !important;
  }
  &__input--last > .v-input__control > .v-input__slot {
    margin-bottom: 0 !important;
  }
  &__input .theme--light.v-label {
    color: white !important;
  }
  &__input--last .v-text-field__details {
    min-height: 0 !important;
  }
}
.theme--light.v-input input {
  color: white !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: white !important;
}
.v-application--is-ltr .v-text-field .v-label {
  opacity: 0.2;
}
</style>
