<template>
  <div class="map">
    <v-row class="d-flex mx-0">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" class="map__col">
        <p class="mb-0 map__page black--text">06 / The countries which we work with</p></v-col
      >
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="d-flex map__countries pa-0">
        <p v-for="item in items" :key="item.name" class="map__name mb-0 black--text">{{ item.name }}</p></v-col
      >
      <v-col cols="12" class="pa-0">
        <div class="map__img">
          <img
            v-if="breakpoint.lgOnly || breakpoint.xlOnly"
            style="width: 100%"
            src="@/assets/svgsprite/map.svg"
            alt="map"
          />
          <img
            v-if="breakpoint.smOnly || breakpoint.mdOnly"
            style="width: 100%"
            src="@/assets/svgsprite/map-768.svg"
            alt="map"
          />
          <img v-if="breakpoint.xsOnly" style="width: 100%" src="@/assets/svgsprite/map-375.svg" alt="map" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageCountries',
  data() {
    return {
      items: [
        { name: 'The USA' },
        { name: 'Western Europe' },
        { name: 'Canada' },
        { name: 'Mexico' },
        { name: 'China' },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style scoped lang="scss">
.map {
  padding: 100px 200px 100px 200px;

  &__img {
    margin-top: 60px;
  }
  &__name {
    margin-right: 15px;
  }
  &__page,
  &__name {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  &__countries {
    flex-direction: inherit;
    align-items: center;
  }

  @media (max-width: 1264px) {
    padding: 50px 114px 50px 114px;

    &__img {
      margin-top: 25px;
    }
    &__countries {
      justify-content: center;
    }
    &__page,
    &__name {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 960px) {
    &__countries {
      margin-top: 13px;
    }
  }
  @media (max-width: 640px) {
    padding: 40px 0 40px 0;

    &__col {
      padding: 0;
    }
    &__countries {
      margin-top: 160px;
      margin-bottom: 160px;
      flex-direction: column;
      align-items: center;
    }
    &__page {
      font-size: 14px;
      line-height: 17px;
      padding: 0 12px 0 12px;
    }
    &__name {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
  }
}
</style>
