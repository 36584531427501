<template>
  <div class="d-flex desktop" style="overflow: hidden">
    <div class="mr-3 d-flex flex-column" style="heigth: auto" cols="2">
      <p class="white--text desktop__page pa-4">04 / How it works</p>
      <div style="flex-grow: 1" />
      <div class="d-flex pa-4">
        <v-btn class="pa-0 mr-2 desktop__arrow" width="60" height="60" tile @click="onPrev"
          ><v-icon class="desktop__icon">mdi-arrow-left</v-icon></v-btn
        >
        <v-btn class="pa-0 desktop__arrow" tile width="60" height="60" @click="onNext"
          ><v-icon class="desktop__icon">mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>

    <div class="desktop__slick_wrap">
      <slick ref="slick" :options="slickOptions" class="desktop__slick">
        <div v-for="(item, i) in items" :key="i">
          <div id="card_padding" class="desktop__card_padding">
            <v-card class="desktop__card d-flex flex-column justify-space-between mr-3">
              <div class="mb-4">
                <img class="desktor__img" :data-lazy="`./img/${item.img}`" />
              </div>
              <div class="d-flex desktop__div">
                <p class="desktop__numb mb-0">{{ item.numb }}</p>
                <p class="desktop__name mb-0" :class="item.pr ? 'pr-0' : ''">{{ item.name }}</p>
              </div>
              <p class="desktop__text">{{ item.text }}</p>
            </v-card>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import 'slick-carousel/slick/slick.css';
import Slick from 'vue-slick';

export default {
  name: 'PageSteps',

  components: { Slick },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    scrollState: null,

    slickOptions: {
      slidesToShow: 3,
      arrows: false,
      lazyLoad: 'progressive',
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    },
  }),

  methods: {
    onNext() {
      this.$refs.slick.next();
    },
    onPrev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style scoped lang="scss">
.desktop {
  margin-left: 100px;
  margin-right: 0px;
  padding: 100px 0px;

  &____name,
  &__text,
  &__numb,
  &__page {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    color: #060d20;
  }

  &__card_padding {
    padding: 25px;
  }
  &__slick {
    width: 1650px;
  }
  &__slick_wrap {
    width: 1100px;
  }
  &__div {
    margin-bottom: 20px;
  }
  &__card {
    padding: 50px;
    height: 520px;
  }
  &__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  &__text,
  &__numb,
  &__page {
    font-size: 18px;
    line-height: 21px;
  }
  &__numb {
    font-weight: 600;
    margin-right: 20px;
  }
  &__text {
    margin-left: 40px;
  }
  &__name {
    padding-right: 0px;
  }
  .slick-slide img {
    width: 395px;
    height: 258px;
  }

  @media (max-width: 1264px) {
    &__page {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 1000px) {
    &__icon {
      font-size: 16px !important;
    }
    &__arrow {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
    }

    &__card_padding {
      padding: 15px;
    }
    &__slick {
      width: 660px;
    }
    &__slick_wrap {
      width: 440px;
    }
    &__div {
      margin-bottom: 5px;
    }
    &__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &__text,
    &__numb {
      font-size: 12px;
      line-height: 14px;
    }

    &__name {
      padding-right: 7px;
    }
    &__numb {
      padding-top: 2px;
      margin-right: 5px;
    }
    &__text {
      margin-left: 20px;
    }
    &__card {
      padding: 10px;
      width: 190px;
      height: 265px;
    }
    .slick-slide img {
      width: 170px !important;
      height: 102px !important;
    }
  }
}
.theme--light.v-sheet {
  background-color: transparent !important;
  border-color: transparent !important;
  margin-right: 0 !important;
}
.theme--light.v-card {
  background-color: white !important;
}
</style>
