<template>
  <div class="competencies">
    <p class="competencies__page black--text pa-0 pb-2">03 / Our core competencies</p>

    <v-row dense style="color: #060d20" class="mb-10">
      <v-card class="mb-4" style="width: 100%">
        <v-col cols="12" class="text-h6 competencies__name px-6"> Marketing and branding</v-col>
        <v-col cols="12" class="competencies__p">
          <p class="px-3">Analyzing the market size, competitive environment.</p>
          <p class="px-3">We help to develop a product portfolio.</p>
          <p class="px-3">Evaluating the potential and opportunities of work.</p>
          <p class="px-3">Adapting / creating brands.</p>
          <p class="px-3">Managing trade marketing on marketplaces.</p>
          <p class="px-3">Producing and adapting content / making creatives.</p>
          <p class="px-3">Creating development strategies for new markets.</p>
          <p class="px-3">Creating strategies (product, digital communications) and managing them.</p>
        </v-col>
      </v-card>
      <v-card class="mb-4" style="width: 100%">
        <v-col cols="12" class="text-h6 competencies__name px-6"> E-commerce</v-col>
        <v-col cols="12" class="competencies__p">
          <p class="px-3">Working with reviews and feedback.</p>
          <p class="px-3">Placing products on the most suitable marketplaces.</p>
          <p class="px-3">Managing sales and stocks using effective models.</p>
          <p class="px-3">Effective communication skills in negotiations with key marketplaces.</p>
        </v-col>
      </v-card>
      <v-card style="width: 100%">
        <v-col cols="12" class="text-h6 competencies__name px-6"> Processes and operations</v-col>
        <v-col cols="12" class="competencies__p">
          <p class="px-3">Managing transport logistics, provide full cycle of turnkey logistics services.</p>
          <p class="px-3">We help to adapt processes (planning demand, logistics, production, etc.)</p>
          <p class="px-3">Optimizing the offer by managing the logistics options for marketplace.</p>
          <p class="px-3">
            We receive all necessary permits: cargo certificates and all documents for import and sales of goods.
          </p>
        </v-col>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageCompetencies',
};
</script>

<style scoped lang="scss">
.competencies {
  &__page,
  &__p {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    v-colne-height: 17px;
  }
  &__name {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    v-colne-height: 21px;
  }
}
p {
  margin-bottom: 10px;
}
</style>
