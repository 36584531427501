/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="values">
    <v-row class="values__page black--text">
      <v-col cols="12" class="py-0">
        <p class="pa-0 values__page--our">01 / Our values</p>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4" class="py-0 d-flex align-items values__img--col">
        <img v-if="!breakpoint.xsOnly" class="values__page--large" width="100%" :src="`./img/${img}.png`" />
        <img v-else width="100%" class="values__page--img" :src="`./img/${imgMob}.png`" />
      </v-col>
      <v-col cols="12" xs="12" sm="8" md="8" lg="8" class="d-flex align-center values__page--block">
        <v-row class="d-flex" dense>
          <v-col cols="6" xs="6" sm="6" md="6" lg="6" class="values__page--cols">
            <div class="values__page--div">
              <p class="values__page values__page--name black--text">Strategy</p>
              <p class="values__page--text">Growth oriented</p>
              <p class="values__page--name mb-0">
                Increased product offerings for consumer variability and convenience. The freedom to choose cool and
                quality products that you enjoy.
              </p>
            </div>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="6" class="values__page--cols">
            <div class="values__page--div">
              <p class="values__page values__page--name">Approach</p>
              <p class="values__page--text">Customer focused</p>
              <p class="values__page--name mb-0">
                Our business is built around a human's life. Sustainability, comfort and enjoyment of life as the main
                principle.
              </p>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6">
            <div class="values__page--div">
              <p class="values__page values__page--name">Relationships</p>
              <p class="values__page--text">Transparent & clear</p>
              <p class="values__page--name mb-0">
                Our business and processes are clear, transparent and open to our partners. We always welcome feedback
                and suggestions.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageOurValues',

  data() {
    return {
      img: 'unsplash',
      imgMob: 'unsplash-mob',
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>

<style scoped lang="scss">
.values {
  &__page {
    &--our,
    &--text,
    &--name {
      font-family: 'San Francisco Pro';
      font-style: normal;
      font-weight: 400;
    }
    &--cols {
      margin-bottom: 60px;
    }
    &--our {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 50px;
    }
    &--text {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 29px;
    }
    &--name {
      margin-bottom: 6px;
      font-size: 18px;
      line-height: 21px;
    }

    &--block {
      padding: 0 0 0 60px;
    }
    &--card {
      padding-bottom: 0;
      margin-bottom: 35px;
    }
  }
  @media (max-width: 1264px) {
    &__page {
      &--cols {
        margin-bottom: 15px;
      }
      &--text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
      &--our {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 15px;
      }
      &--img {
        width: 160px;
      }
      &--name {
        font-size: 12px;
        line-height: 14px;
      }

      &--block {
        padding: 0 0 0 30px;
      }
      &--card {
        padding-bottom: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 690px) {
    &__img {
      &--col {
        align-items: center;
      }
    }
    &__page {
      &--large {
        width: 100%;
        height: 80%;
      }
    }
  }
  @media (max-width: 640px) {
    &__page {
      &--large {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    &__page {
      &--cols {
        margin-bottom: 12px;
      }
      &--our {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 30px;
      }

      &--text {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 5px;
      }
      &--name {
        padding-bottom: 3px;
        font-size: 12px;
        line-height: 14px;
      }
      &--img {
        width: 100% !important;
        height: 190px;
      }
      &--block {
        padding: 12px 12px 0 12px;
        margin-bottom: 200px;
      }
      &--card {
        padding-bottom: 20px;
      }
    }
  }
}
</style>
