<template>
  <div class="partners">
    <p class="partners__page black--text">05 / Partners</p>
    <v-row class="partners__row">
      <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="partners__img">
        <v-card class="d-flex align-center justify-center">
          <div class="partners__img--div"><img class="partners__img--amazon" :src="`./img/${amazon}`" /></div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4">
        <v-card class="d-flex align-center justify-center">
          <div class="partners__img--div"><img class="partners__img--amazon" :src="`./img/${amazonDe}`" /></div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4">
        <v-card class="d-flex align-center justify-center">
          <div class="partners__img--div"><img class="partners__img--walmart" :src="`./img/${walmart}`" /></div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4">
        <v-card class="d-flex align-center justify-center">
          <div class="partners__img--div"><img class="partners__img--esty" :src="`./img/${esty}`" /></div>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4">
        <v-card class="d-flex align-center justify-center">
          <div class="partners__img--div"><img class="partners__img--ebay" :src="`./img/${ebay}`" /></div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PagePartners',
  data() {
    return {
      amazon: 'amazon-logo.png',
      amazonDe: 'amazon-de-logo.png',
      walmart: 'walmart-logo.png',
      esty: 'esty-logo.png',
      ebay: 'ebay-logo.png',
    };
  },
};
</script>

<style scoped lang="scss">
.partners {
  .v-card {
    height: 150px;
  }
  &__page {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 38px;
  }
  &__img {
    &--amazon {
      width: 176px;
    }
    &--walmart {
      width: 142px;
    }
    &--esty,
    &--ebay {
      width: 105px;
    }
  }
  @media (max-width: 1264px) {
    &__page {
      font-size: 12px;
      line-height: 14px;
    }
    .v-card {
      height: 130px;
    }
  }
  @media (max-width: 1000px) {
    .v-card {
      height: 67px;
    }
    &__page {
      margin-bottom: 8px;
    }
    &__img {
      &--amazon {
        width: 81px;
      }
      &--walmart {
        width: 66px;
      }
      &--esty,
      &--ebay {
        width: 50px;
      }
    }
  }
  @media (max-width: 600px) {
    &__row {
      margin-bottom: 135px;
    }
    &__page {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 17px;
    }
    .v-card {
      height: 100px;
    }
    &__img {
      &--amazon {
        width: 176px;
      }
      &--walmart {
        width: 142px;
      }
      &--esty,
      &--ebay {
        width: 105px;
      }
    }
  }
}
</style>
