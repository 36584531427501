<template>
  <div class="competencies pb-13">
    <p class="competencies__page black--text">03 / Our core competencies</p>
    <v-container v-for="(texts, n) in gridItems" :key="'container' + n" class="competencies__container">
      <v-card class="py-6 px-2">
        <v-row no-gutters>
          <v-col v-for="(text, i) in texts" :key="'text' + i" cols="4" class="px-4">
            <p v-if="i === 0" class="competencies__p4title black--text">{{ text }}</p>
            <p v-else class="competencies__p4regular">{{ text }}</p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PageFour',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    gridItems() {
      if (this.items) {
        return this.items.map((item) => this.convertItems(item));
      } else {
        return [];
      }
    },
  },
  methods: {
    convertItems(item) {
      const textItems = [item.name, ...item.text.map((e) => e.li)];
      const length = textItems.length;
      const rows = Math.ceil(length / 3);
      const cols = 3;

      let arr = Array.from(Array(cols), () => Array.from(new Array(rows), () => ''));

      let index = 0;
      for (let i = 0; i < cols /* columns */; i++) {
        for (let j = 0; j < rows; j++) {
          if (index < textItems.length) {
            arr[i][j] = textItems[index];
          } else {
            arr[i][j] = '';
          }
          index++;
        }
      }

      let result = [];
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          result.push(arr[j][i]);
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss">
.container {
  @media (max-width: 1300px) {
    max-width: 1200px;
    padding: 0px 90px 20px 90px;
  }
  @media (max-width: 960px) {
    max-width: 900px !important;
  }
}
.competencies {
  font-family: 'San Francisco Pro';
  font-style: normal;
  font-weight: 400;
  &__page {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 50px 114px 0px 114px;
  }
  @media (max-width: 1000px) {
    &__container {
      padding: 0px 90px 20px 90px;
    }
  }
  @media (max-width: 640px) {
    &__page {
      padding: 40px 14px 0px 14px;
    }
    &__container {
      padding: 0px 14px 20px 14px;
    }
  }

  &__p4title {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  &__p4regular {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #060d20;
  }
}
</style>
