<template>
  <div>
    <Large v-if="breakpoint.smOnly || breakpoint.mdOnly || breakpoint.lgOnly || breakpoint.xlOnly" />
    <Small v-else />
  </div>
</template>

<script>
import Large from './large.vue';
import Small from './small.vue';

export default {
  name: 'PageCompetencies',
  components: { Large, Small },

  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>
