<template>
  <div>
    <div>
      <PageMerulat />
    </div>
    <div class="pages pages__our">
      <PageOurValues />
    </div>
    <div id="advantages" class="pages pages__advanrages white--text">
      <PageAdvantages />
    </div>
    <div id="competencies" class="pages__competencies">
      <PageCompetencies />
    </div>
    <div id="howItWorks" class="pages__steps">
      <PageSteps />
    </div>
    <div id="partners" class="pages pages__partners">
      <PagePartners />
    </div>
    <div id="countries" class="pages__countries">
      <PageCountries />
    </div>

    <PageForm id="form-contact" class="pages__form" />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import PageMerulat from './page/PageMerulat';
import PageOurValues from './page/PageOurValues';
import PageAdvantages from './page/PageAdvantages';
import PageCompetencies from './page/competencies/PageCompetencies';
import PageSteps from './page/steps/PageSteps';
import PagePartners from './page/PagePartners';
import PageCountries from './page/PageCountries';
import PageForm from './page/form/PageForm';

export default {
  name: 'MainPage',

  components: {
    PageMerulat,
    PageOurValues,
    PageAdvantages,
    PageCompetencies,
    PageSteps,
    PagePartners,
    PageCountries,
    PageForm,
  },
  data() {
    return {
      size: null,
    };
  },
  methods: {
    elem() {
      this.size = window.innerWidth;
      return this.size;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/fonts/fonts.css';

.pages {
  background-size: cover;
  padding: 100px 200px 100px 200px;
  background-size: auto;

  &__advanrages {
    background-color: #316cf4;
    background-image: url('../assets/img/fond/advanrages-1600.png');
    background-position: 50% 145%;
    background-size: contain;
  }

  @media (max-width: 1800px) {
    &__advanrages {
      background-position: 50% 127%;
    }
  }
  &__our {
    background-image: url('../assets/img/fond/our-values-1600.png');
    background-position: 50% 104%;
  }

  &__competencies {
    background-image: url('../assets/img/fond/competencies-1600.png');
    background-position: 50% 113%;
    background-size: contain;
  }
  &__steps {
    background-color: #316cf4;
    background-image: url('../assets/img/fond/steps-768.png');
    background-position: 50% 125%;
    background-size: contain;
  }
  &__partners {
    background-position: 50% 108%;
    background-color: #f6f6f6;
    background-image: url('../assets/img/fond/partners-1600.png');
  }
  &__countries {
    background-image: url('../assets/img/fond/countries-1600.png');
    background-position: 50% 103%;
  }
  &__form {
    background-color: #316cf4;
  }
  @media (max-width: 1500px) {
    &__competencies {
      background-position: 50% 109%;
    }
    &__steps {
      background-position: 50% 119%;
    }
    &__advanrages {
      background-position: 50% 115%;
    }
  }
  @media (max-width: 1264px) {
    padding: 50px 114px 50px 114px;
    &__our {
      background-image: url('../assets/img/fond/our-values-768.png');
      background-position: 50% 110%;
    }
    &__advanrages {
      background-image: url('../assets/img/fond/advanrages-768.png');
      background-position: 50% 183%;
      background-size: contain;
    }
    &__steps {
      background-position: 50% 115%;
    }
    &__competencies {
      background-image: url('../assets/img/fond/competencies-768.png');
      background-size: contain;
      background-position: 50% 125%;
    }
    &__partners {
      background-position: 50% 111%;
    }
    &__countries {
      background-position: 50% 108%;
    }
    &__our,
    &__countries,
    &__partners {
      background-size: contain;
    }
  }
  @media (max-width: 1150px) {
    &__advanrages {
      background-position: 50% 160%;
    }
    &__competencies {
      background-position: 50% 118%;
    }
    &__steps {
      background-position: 50% 112%;
    }
  }
  @media (max-width: 1000px) {
    &__competencies {
      background-position: 50% 114%;
    }
    &__advanrages {
      background-position: 50% 136%;
    }
    &__steps {
      background-position: 50% 122%;
    }
  }
  @media (max-width: 900px) {
    &__competencies {
      background-position: 50% 110%;
    }
    &__advanrages {
      background-position: 50% 125%;
    }
    &__steps {
      background-position: 50% 116%;
    }
    &__countries {
      background-position: 50% 103%;
    }
  }

  @media (max-width: 795px) {
    &__advanrages {
      background-image: url('../assets/img/fond/advanrages-768.png');
      background-position: 50% 122%;
    }
    &__competencies,
    &__our {
      background-position: 50% 108%;
    }
    &__steps {
      background-image: url('../assets/img/fond/steps-768.png');
      background-position: 50% 116%;
    }
    &__partners {
      background-image: url('../assets/img/fond/partners-768.png');
      background-position: 50% 110%;
    }
    &__countries {
      background-image: url('../assets/img/fond/countries-768.png');
      background-position: 50% 105%;
    }
  }
  @media (max-width: 750px) {
    &__advanrages {
      background-position: 50% 115%;
    }
    &__partners,
    &__countries {
      background-position: 50% 106%;
    }
  }
  @media (max-width: 700px) {
    &__competencies {
      background-position: 50% 106%;
    }
    &__steps {
      background-position: 50% 110%;
    }
  }
  @media (max-width: 640px) {
    padding: 40px 14px 40px 14px;
    &__countries {
      background-position: 0% 101%;
    }
    &__our {
      background-position: 0% 106%;
    }
  }
  @media (max-width: 600px) {
    &__competencies {
      background-image: url('../assets/img/fond/competencies-375.png');
      background-position: 50% 105%;
    }
    &__our {
      background-image: url('../assets/img/fond/our-values-375.png');
      background-position: 50% 102%;
    }
    &__advanrages {
      background-image: url('../assets/img/fond/advanrages-375.png');
      background-position: 50% 107%;
    }
    &__steps {
      background-image: url('../assets/img/fond/steps-375.png');
      background-position: 50% 106%;
    }
    &__partners {
      background-image: url('../assets/img/fond/partners-375.png');
      background-position: 50% 102%;
    }
    &__countries {
      background-position: 0% 102%;
      background-size: auto;
    }
  }
  @media (max-width: 380px) {
    &__countries {
      background-image: url('../assets/img/fond/countries-375.png');
    }
    &__advanrages {
      background-position: 50% 105%;
    }
  }
}
</style>
