<template>
  <div class="competencies">
    <p style="font-size: 18px; line-height: 21px" class="black--text">03 / Our core competencies</p>

    <v-row class="d-flex">
      <v-col v-for="item in items" :key="item.name" cols="6" xs="12" sm="6" md="4" lg="4">
        <v-card class="py-12 px-6">
          <p class="competencies__name black--text">{{ item.name }}</p>
          <li v-for="li in item.text" :key="li.li" class="pb-4 competencies__li">{{ li.li }}</li>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageFour',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.competencies {
  &__li {
    color: #060d20;
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;

    font-size: 18px;
    line-height: 21px;
  }
  &__name {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
}
</style>
