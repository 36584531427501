<template>
  <div class="mobile">
    <p class="white--text mobile__page">04 / How it works</p>
    <v-carousel cycle :interval="interval" hide-delimiter-background :show-arrows="false" height="auto">
      <v-carousel-item v-for="(item, i) in items" :key="i" class="mb-8">
        <div class="pa-0" style="mobile__content">
          <img
            class="mb-4"
            :src="`./img/${item.img}`"
            style="object-fit: cover; width: 100%; aspect-ratio: 347 / 539"
          />

          <v-row class="mb-5">
            <v-col cols="1">
              <p class="mobile__numb white--text">{{ item.numb }}</p>
            </v-col>
            <v-col cols="11">
              <p class="mobile__name white--text">{{ item.name }}</p>
              <p class="mobile__text white--text">{{ item.text }}</p>
            </v-col>
          </v-row>
        </div></v-carousel-item
      >
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: 'Small',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      interval: 4000,
    };
  },
};
</script>
<style lang="scss" scoped>
.v-carousel {
  height: 980px !important;

  @media (max-width: 550px) {
    height: 920px !important;
  }
  @media (max-width: 500px) {
    height: 870px !important;
  }
  @media (max-width: 480px) {
    height: 850px !important;
  }
  @media (max-width: 440px) {
    height: 800px !important;
  }
  @media (max-width: 420px) {
    height: 780px !important;
  }
  @media (max-width: 400px) {
    height: 760px !important;
  }
  @media (max-width: 390px) {
    height: 730px !important;
  }
}
</style>

<style scoped lang="scss">
.mobile {
  &__page,
  &__name,
  &__numb,
  &__text {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    color: #060d20;
  }
  padding: 40px 14px 0px 14px;

  &__page {
    font-size: 14px;
    line-height: 17px;
  }
  &__name,
  &__numb {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
  &__text {
    font-size: 14px;
    line-height: 17px;
  }
}
.theme--light.v-sheet {
  background-color: transparent !important;
  border-color: transparent !important;
  margin-right: 0 !important;
}
.theme--light.v-card {
  background-color: white !important;
}
</style>
