<template>
  <div class="form">
    <v-img class="d-flex align-center" :src="`./img/${imgMob}.png`" style="width: 100%; height: 100%">
      <p class="white--text text-center px-4 form__header">
        We are always here to help you with any of your questions.
      </p></v-img
    >

    <Form class="mt-16" />

    <div class="white--text mb-4 form__footer">
      <p>
        <a href="mailto:info@merulat.com" class="white--text text-decoration-none">info@merulat.com</a>
      </p>
      <p class="mb-0">651 N Broad St, Ste 205 #6043 Middletown, Delaware 19709</p>
    </div>
  </div>
</template>

<script>
import Form from './Form.vue';

export default {
  name: 'Small',
  components: { Form },
  data() {
    return {
      imgMob: 'form-375',
    };
  },
};
</script>

<style scoped lang="scss">
.form {
  a:hover {
    color: black !important;
  }
  &__footer,
  &__header {
    font-family: 'San Francisco Pro';
    font-style: normal;
  }

  &__header {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  &__footer {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-left: 14px;
  }
}
</style>
