<template>
  <div class="carusel" style="display: grid; grid-template-columns: 100%; grid-template-areas: 'rec1'">
    <!-- контент -->
    <div style="height: 100%; grid-area: rec1; width: 100%; z-index: 1; pointer-events: none">
      <div v-if="!breakpoint.xsOnly" class="d-flex justify-center carusel__text white--text">
        <div v-for="header in headers" :key="header.text" class="carusel__link mr-5" @click="onHeaderClicked(header)">
          {{ header.text }}
        </div>
      </div>

      <div class="d-flex justify-center align-center flex-column carusel__details">
        <p class="carusel__details--name white--text">MERULAT</p>
        <p class="carusel__details--one pa-0 white--text">International Distributor 360&deg;</p>
        <p class="text-center carusel__details--two white--text">
          Our E-commerce, Marketing and Logistics expertise create equal opportunities for growth of both emerging and
          traditional companies in order to provide world’s consumers with quality and necessary products.
        </p>
        <div v-if="breakpoint.xsOnly" style="width: 100%; padding: 0px 16px">
          <v-btn
            color="white"
            tile
            style="height: 50px !important; width: 100%; pointer-events: auto"
            @click="onHeaderClicked('form-contact')"
          >
            <div class="carusel__details--btn">Contact us</div>
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            style="pointer-events: auto"
            color="white"
            tile
            class="carusel__derails--contact"
            @click="onHeaderClicked('form-contact')"
          >
            <div class="carusel__details--btn">Contact us</div>
            <v-icon color="black">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <v-carousel
      cycle
      :interval="interval"
      :show-arrows="false"
      hide-delimiter-background
      height="100%"
      style="grid-area: rec1; width: 100%; height: 100%; z-index: 0; display: inline-block"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" cycle eager style="height: 100%">
        <div style="height: 100%; width: 100%; display: flex">
          <img
            :src="`./img/${item.img}`"
            style="object-fit: cover; height: 100%; width: 100%; position: absolute; filter: brightness(0.7)"
          />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: 'PageMerulat',

  data() {
    return {
      interval: 7000,
      headers: [
        {
          id: 'advantages',
          text: 'Advantages',
        },
        {
          id: 'competencies',
          text: 'Core competencies',
        },
        {
          id: 'howItWorks',
          text: 'How it works',
        },
        {
          id: 'partners',
          text: 'Partners',
        },
        {
          id: 'countries',
          text: 'Countries',
        },
      ],
      items: [
        {
          img: 'merulat-1.jpg',
        },
        {
          img: 'merulat-2.png',
        },
        {
          img: 'merulat-3.jpg',
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    onHeaderClicked(header) {
      if (header.id) document.getElementById(header.id).scrollIntoView({ behavior: 'smooth' });
      else document.getElementById(header).scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.v-btn--round .v-btn__content .v-icon {
  font-size: 12px !important;
  height: 20px !important;
  width: 20px !important;
}
</style>

<style scoped lang="scss">
.v-carousel .v-window-item {
  background-size: cover !important;
}
.carusel {
  &__link {
    cursor: pointer;
    pointer-events: auto;
  }
  &__link:hover {
    color: #0d6efd; /* Цвет ссылки */
  }
  .v-btn {
    height: 60px !important;
  }

  &__text {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 70px;
  }
  &__details {
    margin-top: 65px;
    // высота блока
    height: 780px;

    &--btn,
    &--one,
    &--two {
      font-family: 'San Francisco Pro';
      font-style: normal;
      font-weight: 400;
    }

    &--btn {
      color: black;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      margin-right: 29px;
      text-transform: initial;
    }

    &--name {
      font-weight: 700;
      font-size: 122px;
      line-height: 141px;
      text-transform: uppercase;

      font-family: 'Halvar Breitschrift';
    }
    &--one {
      font-size: 28px;
      line-height: 33px;
      letter-spacing: 0.05em;
      margin-bottom: 30px;
    }
    &--two {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      width: 750px;
      margin-bottom: 80px;
    }
  }

  @media (max-width: 1264px) {
    .v-btn > .v-btn__content .v-icon {
      font-size: 14px;
    }
    .v-btn {
      height: 29px !important;
      padding: 0 6px !important;
    }

    &__text {
      margin-top: 30px;
      font-size: 12px;
      line-height: 14px;
    }
    &__details {
      margin-top: 0px;

      // высота блока
      height: 500px;

      &--btn {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-right: 5px;
        color: #000000;
        margin-right: 12px;
      }
      &--name {
        font-weight: 700;
        font-size: 47px;
        line-height: 54px;
        margin-bottom: 0px;
      }
      &--one {
        font-size: 14px;
        line-height: 17px;
      }
      &--two {
        font-size: 12px;
        line-height: 14px;
        padding: 0px 180px 0px 180px;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 800px) {
    &__details {
      &--two {
        padding: 0;
        width: 400px;
      }
    }
  }
  @media (max-width: 600px) {
    &__details {
      margin-top: 0px;
      height: 710px;

      &--name {
        margin-top: 200px;
      }
      &--one {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 17px;
      }
      &--two {
        margin-bottom: 218px;
        padding: 0px 32px;
        width: 100%;

        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  @media (max-width: 400px) {
    &__details {
      &--two {
        margin-bottom: 170px;
      }
    }
  }
}
</style>
