<template>
  <Large v-if="breakpoint.lgOnly || breakpoint.xlOnly" class="large" :items="items" />
  <Medium v-else-if="breakpoint.smOnly || breakpoint.mdOnly" class="medium" :items="items" />
  <Small v-else-if="breakpoint.xsOnly" class="small" />
</template>

<script>
import Large from './large.vue';
import Medium from './medium.vue';
import Small from './small.vue';

export default {
  name: 'PageCompetencies',
  components: { Large, Medium, Small },
  data() {
    return {
      items: [
        {
          name: 'Marketing and branding',
          text: [
            {
              li: 'Analyzing the market size, competitive environment.',
            },
            {
              li: 'Evaluating the potential and opportunities of work.',
            },
            {
              li: 'Adapting / creating brands.',
            },
            {
              li: 'Creating development strategies for new markets.',
            },
            {
              li: 'Creating strategies (product, pricing, digital communications) and  managing them.',
            },
            {
              li: 'We help to develop a product portfolio.',
            },
            {
              li: 'Managing trade marketing on marketplaces.',
            },
            {
              li: 'Producing and adapting content / making creatives.',
            },
          ],
        },
        {
          name: 'E-commerce',
          text: [
            {
              li: 'Managing sales and stocks using effective models.',
            },
            {
              li: 'Placing products on the most suitable marketplaces',
            },
            {
              li: 'Working with reviews and feedback.',
            },
            {
              li: 'Effective communication skills in negotiations with key marketplaces.',
            },
          ],
        },
        {
          name: 'Processes and operations',
          text: [
            {
              li: 'Optimizing the offer by managing the logistics options for marketplace.',
            },
            {
              li: 'We help to adapt processes (planning demand, logistics, production, etc.)',
            },
            {
              li: 'Managing transport logistics, provide full cycle of turnkey logistics services (choice of the optimal logistics routes and options, developed competencies in highly qualified customs clearances, delivery of goods on a turnkey basis).',
            },
            {
              li: 'We receive all necessary permits: cargo certificates and all documents for import and sales of goods.',
            },
          ],
        },
      ],
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
};
</script>
<style lang="scss">
.large {
  padding: 100px 200px 100px 200px;

  @media (max-width: 1264px) {
    padding: 50px 114px 50px 114px;
  }
}
.small {
  padding: 40px 14px 40px 14px;
}
</style>
