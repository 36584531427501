<template>
  <div class="advantages">
    <v-row class="d-flex">
      <v-col cols="12"> <p class="advantages__page">02 / Competitive advantages</p></v-col>
      <v-col v-for="item in items" :key="item.name" cols="6" xs="12" sm="4" md="4" lg="4">
        <div>
          <img :width="item.width" :src="require(`@/assets/svgsprite/${item.img}.svg`)" alt="map" />
          <p class="advantages__text">{{ item.description }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageAdvantages',

  data() {
    return {
      items: [
        {
          width: '22',
          img: 'eye',
          description:
            'We understand the specific steps of the production process and therefore offer both ready made interaction models and an individual approach for any size and type of production.',
        },
        {
          width: '21',
          img: 'sale',
          description:
            'We can fully outsource your commercial and marketing functions, which will allow you to control your core interests and competencies.',
        },
        {
          width: '14',
          img: 'radix',
          description:
            'We have built work processes with companies of various sizes: from hand made specialists to national companies looking for access to international markets.',
        },
        {
          width: '19',
          img: 'akar',
          description:
            'The sales and E-commerce expertise of our team allows us to sell the products from our partners on leading marketplaces and offline stores.',
        },

        {
          width: '12',
          img: 'what',
          description:
            'Our one stop marketing support center helps partners create, adapt and develop brands and promote products in the global market.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.advantages {
  margin-bottom: 0;

  &__text,
  &__page {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }
  &__page {
    margin-bottom: 35px;
  }
  @media (max-width: 1264px) {
    &__text,
    &__page {
      font-size: 12px;
      line-height: 14px;
    }
    &__page {
      margin-bottom: 11px;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 200px;
    &__text,
    &__page {
      font-size: 14px;
      line-height: 17px;
    }
    &__page {
      margin-bottom: 11px;
    }
  }
}
</style>
