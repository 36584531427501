<template>
  <div class="form white--text">
    <v-row dense class="ma-0">
      <v-col cols="4" class="pa-0">
        <img :src="`./img/${img}.png`" style="width: 100%; height: 100%" />
      </v-col>
      <v-col cols="8" class="d-flex align-center justify-center">
        <div class="form__values ma-0 d-flex flex-column">
          <v-row class="d-flex justify-space-between">
            <v-col cols="6" md="5" lg="5" class="pt-4 form__values--text">
              <p class="form__values__header">We are always here to help you with any of your questions.</p>
            </v-col>
            <v-col cols="6" xs="12" md="6" lg="6">
              <Form />
            </v-col>
          </v-row>

          <div class="d-flex form__footer">
            <p class="mr-16 form__footer--info">
              <a href="mailto:info@merulat.com" class="white--text text-decoration-none">info@merulat.com</a>
            </p>
            <p>651 N Broad St, Ste 205 #6043 Middletown, Delaware 19709</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Form from './Form.vue';
export default {
  name: 'Large',

  components: { Form },

  data() {
    return {
      img: 'form-1600',
    };
  },
};
</script>

<style lang="scss">
.form {
  a:hover {
    color: black !important;
  }
  &__text,
  &__footer,
  &__btn {
    font-family: 'San Francisco Pro';
    font-style: normal;
    font-weight: 400;
  }

  background-color: #316cf4;

  &__values {
    padding: 0 200px 0 0;
    &--text {
      margin: 0 10px 0 10px;
    }
    &--header {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    font-size: 18px;
    line-height: 21px;
  }
  &__btn {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
  .theme--light.v-btn {
    max-width: 60px;
    height: 60px;
    min-width: 60px;
    padding: 0;
  }
  @media (max-width: 1264px) {
    .theme--light.v-btn {
      max-width: 30px;
      min-width: 30px;
      height: 30px;
      padding: 0;
    }
    &__footer {
      flex-direction: column;
      font-size: 12px;
      line-height: 14px;
      margin-left: 25px;
      &--info {
        margin-bottom: 5px !important;
      }
    }

    &__btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
    &__values {
      padding: 0 114px 0 0;

      &--text {
        margin: 0 0 0 0;
        padding-left: 47px;
      }
      &--text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  @media (max-width: 600px) {
    &__btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 640px) {
    &__values {
      padding-right: 14px;
      padding-bottom: 40px;
    }
  }
}
</style>
